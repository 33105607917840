<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title> ยืม </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-date v-model="Lend.LendDate"></v-date>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="จำนวนเงิน"
                  placeholder="จำนวนเงิน"
                  dense
                  v-model="Lend.LendTotal"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="ดอกเบี้ย/เดือน"
                  placeholder="จำนวน"
                  dense
                  v-model="Lend.LendPercent"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  label="ค่างวด/เดือน"
                  placeholder="จำนวน"
                  dense
                  v-model="Lend.LendInstallment"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="8" lg="8">
                <v-text-field
                  label="รายละเอียด"
                  placeholder="รายละเอียด"
                  dense
                  v-model="Lend.LendRemark"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="1" lg="1">
                <v-btn color="primary" width="100%" @click="Insert()">
                  <v-icon>fa fa-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title> ประวัติ </v-card-title>
          <v-data-table
            :items-per-page="-1"
            :headers="headers"
            :items="desserts"
            class="elevation-1"
          >
            <template v-slot:[`item.LendPercentTotal`]="{ item }">
              {{ item.LendPercent * item.LendTotal /  100}}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      User: {},
      Lend: {
        UserId: this.$route.query.UserId,
        LendTotal: "0",
        LendRemark: "",
        LendDate: "",
        LendPercent: "0",
        LendInstallment: "0",
      },
      UserId: this.$route.query.UserId,
      desserts: [],
      headers: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "LendDate",
        },
        {
          text: "รายละเอียด",
          align: "left",
          sortable: true,
          value: "LendRemark",
        },
        {
          text: "จำนวนเงิน",
          align: "left",
          sortable: true,
          value: "LendTotal",
          width: 120,
        },
        {
          text: "ดอกเบี้ย",
          align: "left",
          sortable: true,
          value: "LendPercent",
          width: 100,
        },
        {
          text: "ดอกเบี้ย/เดือน",
          align: "left",
          sortable: true,
          value: "LendPercentTotal",
          width: 130,
        },
        {
          text: "ค่างวด/เดือน",
          align: "left",
          sortable: true,
          value: "LendInstallment",
          width: 130,
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.UserId);
      this.SelectUser(this.UserId);
    });
  },
  methods: {
    Insert() {
      if (Number(this.Lend.LendTotal) <= 0) return;
      if (
        Number(this.User.UserMoneyLimt) -
          (Number(this.User.Stale) + Number(this.Lend.LendTotal)) <
        0
      ) {
        this.PassAccept("insert", () => {
          axios.post("/api/v1/Insert/insert/lend", this.Lend).then((res) => {
            if (res.status == 200) {
              this.Select(this.UserId);
              this.SelectUser(this.UserId);
              this.Prints(res.data);
            }
          });
        });
      } else {
        axios.post("/api/v1/Insert/insert/lend", this.Lend).then((res) => {
          if (res.status == 200) {
            this.Select(this.UserId);
            this.SelectUser(this.UserId);
            this.Prints(res.data);
          }
        });
      }
    },
    SelectUser(v) {
      axios.get("/api/v1/select/SelectId/user?UserId=" + v).then((res) => {
        if (res.status == 200) {
          this.User = res.data;
        }
      });
    },
    Select(v) {
      axios.get("/api/v1/select/select/lend?UserId=" + v).then((res) => {
        if (res.status == 200) {
          this.desserts = res.data;
        }
      });
    },
    Prints(v) {
      axios.get(`/api/v1/FinanceLend/pdf?Id=${v.Id}`).then((res) => {
        if (res.status == 200) {
          this.PrintJs({
            printable: res.data.base64,
            type: "pdf",
            base64: true,
          });
        }
      });
    },
    Delete(v) {
      this.PassAccept("delete", () => {
        axios
          .delete("/api/v1/delete/delete/lend?LendId=" + v.LendId)
          .then((res) => {
            if (res.status == 200) {
              this.Select(this.UserId);
              this.SelectUser(this.UserId);
            }
          });
      });
    },
  },
};
</script>
